import Logins from './Logins.js'
import Notifications from './Notifications.js'
import Validator from './Validator.js'


export {
    Logins,
    Notifications,
    Validator,
};

export default {
    Logins: new Logins(),
    Notifications: new Notifications(),
    Validator: new Validator(),
}