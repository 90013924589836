import './utils/fetchPolyfill';
import './utils/findFirstAndLastIndexOf';
import './utils/canvasWrapText';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Swal from 'sweetalert2';
import { initializePush } from './init-fcm'; 

/**
 * @description Root Element
 */
ReactDOM.render( < App /> , document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting
    
    if (waitingServiceWorker) {
      initializePush(registration);
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          Swal.fire({
            position: 'bottom',
            icon: 'success',
            title: 'Nova versão do app instalada! Deseja recarregar ?',
            width: "100%",
            padding: "5px",
            confirmButtonText: "Sim",
            showConfirmButton: true
          }).then(response => {
            return response.isConfirmed && window.location.reload();
          })
          
        }
      });
      waitingServiceWorker.postMessage({
        type: "SKIP_WAITING"
      });
    }
  },
  onSuccess: registration => {
    initializePush(registration)
    
  }
})
