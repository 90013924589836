import AbstractError from "./AbstractError";
import { ConnectionError } from "../repository/errorsModels";

export default class ConnectionErrorNotfound extends AbstractError {
  constructor(helperData = {}) {
    helperData.devHelper = "Verificar a url que está tentando acessar.";
    helperData = Object.assign(
      {
        message:
          "Caso visualizar este mensagem notificar ao suporte do app. Erro: END_POINT_NOT_FOUND",
        title: "Falha na conexão",
        type: "warning",
      },
      helperData
    );
    super(helperData, new ConnectionError());
  }
}
