import React from "react";
import Text2Picture from '../service/Pictures/Text2Picture';
import {concatImages, base64ToBlob} from '../service/Pictures/Pictures';
// import ShareService from '../service/share';

const PrinterContext = React.createContext({});
const DEFAULT_STATE = {
  title: "",
  open: false,
  content: "",
  viaClient: "",
  viaEmployee: "",
  headerMainText: "",
  headerSecondaryText: "",
  disableSellerButton: false,
  disableClientButton: false,
};

class PrinterContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      open: false,
      content: "",
      viaClient: "",
      viaEmployee: "",
      headerMainText: "",
      onCloseDialog: null,
      headerSecondaryText: "",
      disableClientButton: false,
      disableSellerButton: false,
      handleClose: this.handleClose,
      onClickCloseIcon: this.onClickCloseIcon,
      onClickBackToInit: this.onClickBackToInit,
      onClickSellerButton: () => null,
      onClickClientButton: () => null,
      callPrinterComponent: this.callPrinterComponent,
      printAndShare: this.printAndShare,
      print: this.print,
    };
  }

  onClickSellerButton = async () => {
    await this.print(this.state.viaEmployee);
  };
  onClickClientButton = async () => {
    await this.print(this.state.viaClient);
  };

  handleClose = () => {
    this.state.onCloseDialog && this.state.onCloseDialog();

    this.setState({
      open: false,
      content: "",
      viaClient: "",
      viaEmployee: "",
      onClickSellerButton: console.log,
      onClickClientButton: console.log,
      onCloseDialog: console.log,
    });
  };

  onClickCloseIcon = () => {
    this.setState({ open: false });
    this.state.onCloseDialog && this.state.onCloseDialog();
  };
  onClickBackToInit = () => {
    this.setState({ open: false });
    this.state.onCloseDialog && this.state.onCloseDialog();
  };

  printAndShare = async (invoice) => {
    console.warn("Via vendedor", invoice);
    const blobImage = await Text2Picture.generate(invoice, 26, "blob");
    // await ShareService.shareImage(blobImage, "invoice.png");
  };
  print = (invoice) => {
    console.warn("Via cliente", invoice);
  };

  callPrinterComponent = (info) => {
    const viewState = Object.assign({ ...DEFAULT_STATE }, info);
    this.setState({ ...this.state, ...viewState, open: true });
  };

  render() {
    return (
      <PrinterContext.Provider value={this.state}>
        {this.props.children}
      </PrinterContext.Provider>
    );
  }
}

export { PrinterContextProvider, PrinterContext };
