import React, { lazy } from "react";
import "./App.css";
import AuthPage_Router from "./router/AuthRouter";
import { Router, Switch, Route} from "react-router-dom";
import AppRouter from "./router/AppRouter";
import history from "./router/History";
import { UserContextProvider, UserContext } from "./contexts/UserContext";
import { NeighborhoodContextProvider, NeighborhoodContext } from "./contexts/NeighborhoodContext";
import { MessageDialogContextProvider } from "./contexts/MessageDialogContext";
import MessageDialog from "./components/MessageDialog";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ScrollControl from "./router/ScrollControl";
import { PrinterContextProvider } from "./contexts/PrinterContext";
import { ConfigContextProvider } from "./contexts/ConfigContext";
import { NotificationsContextProvider, NotificationsContext } from "./contexts/NotificationsContext";
import LocalStorage from "./utils/localStorage";
import DeviceWarningPage from "./pages/DeviceWarning";
import firebase from "firebase";
import { getConfigKey, setConfigKey } from './service/config'
import { CircularProgress } from "@material-ui/core";


let theme = createMuiTheme({
  palette: {
    primary: {
      main: "rgb(11, 213, 162)",
    },
  },
});

const CONTEXTS_ARRAY = [
  ConfigContextProvider,
  UserContextProvider,
  NotificationsContextProvider,
  NeighborhoodContextProvider,
  PrinterContextProvider,
  MessageDialogContextProvider,
];




class App extends React.Component {
  constructor(props) {
    super(props);
    this.localStorage = LocalStorage.instance;
  }

  componentDidMount() {
    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker.onmessage = (payload) => {
        this.userContext.setClientNotification(payload);
      }
    }
    this.checkDeveloper()
  }
  checkDeveloper = async () => {
    let isDev = window.location.href.includes('localhost');
    if (isDev != await getConfigKey('isDeveloper'))
      setConfigKey('isDeveloper', isDev);

  }

  render() {
    const {classes} = this.props
    const Inner = (
      <ThemeProvider theme={theme}>
        <React.Suspense fallback={<div style={{height: '100vh', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress color="primary" /></div>}>
          <UserContext.Consumer>
            {(userContext) => {
              if (this.userContext != userContext) this.userContext = userContext;
              return (
                <NotificationsContext.Consumer>
                  {(notificationsContext) => {
                    if (this.notificationsContext != notificationsContext) this.notificationsContext = notificationsContext;
                    return (
                      <Router history={history}>
                        <ScrollControl />
                        {/* <PagesNavigationController/> */}
                        <Switch>
                          {
                            window.screen.width >= 992 ?
                              <Route component={DeviceWarningPage} />
                              :
                              <Route
                                component={ AppRouter }
                              />
                          }
                        </Switch>
                        <MessageDialog></MessageDialog>
                      </Router>
                    )
                  }}
                </NotificationsContext.Consumer>
              );
            }}
          </UserContext.Consumer>
      </React.Suspense>
        </ThemeProvider>
    );

    const Context_Parent = CONTEXTS_ARRAY.reduce(
      (Memo, EL) => <EL>{Memo}</EL>,
      Inner
    );


    return Context_Parent;
  }
}

export default App;
