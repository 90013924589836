export default class Text2Picture {

  static generate(text, lineHeight = 16, returnType = 'base64', width = null) {
    return new Promise(resolve => {
      const padding = 20;
      const fontSize = Text2Picture.findFontSizeByLineHeight(lineHeight);
      const height = (text.split('\n').length*lineHeight) + 2 * padding + 50;
      width = !width ? Text2Picture.findWidthByLineHeigth(lineHeight) : width;
      const canvasElement = document.createElement('canvas');
      canvasElement.width = width;
      canvasElement.height = height;

      const ctx = canvasElement.getContext("2d");



      // Background.
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, width, height);

      ctx.fillStyle = "black";
      ctx.font = `${fontSize}px Roboto, Helvetica, Arial, sans-serif`;
      ctx.textBaseline = "top";
      ctx.wrapText(text, padding, padding, width-(2*padding), lineHeight);

      if(returnType == 'base64'){
        return resolve(canvasElement.toDataURL());
      }

      canvasElement.toBlob(resolve);

    });

  }

  static findFontSizeByLineHeight(lineHeight){
    return 0.75*lineHeight;
  }

  static findWidthByLineHeigth(lineHeight){
    return (220*lineHeight)/16;
  }

}
