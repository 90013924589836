import AbstractError from "./AbstractError";
import { ConnectionError } from "../repository/errorsModels";

export default class ConnectionErrorTimeOut extends AbstractError {
  constructor(helperData = {}) {
    helperData.devHelper =
      "Verifique o endPoint que está causando a lentidão e o numero de requisições que estão sendo feitas";

      helperData = Object.assign(
      {
        message:
          "O servidor está sofrendo lentidão, tente novamente em alguns minutos",
        title: "Falha na conexão",
        type: "warning",
      },
      helperData
    );
    super(helperData, new ConnectionError());
  }
}
