import React, { createContext } from "react";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import ErrorIcon from "@material-ui/icons/Error";

const MessageDialogContext = createContext();

/** Componente responsável por servir como provider do contexto de MessageDialog e também por armazenar o array contendo todos os MessageDialogs existentes, bem como o objeto contendo os tipos de severidade que cada MessageDialog pode ter.
 *
*/

class MessageDialogContextProvider extends React.Component {
  /** @param {Object} props.children Props que contém os children desse componente quando instanciado.
   * @property {array} [dialogsArray=[]] Array que contém objetos de todos os MessageDialogs a serem renderizados.
   * @property {Object} dialogsTypes Array que contém todos as propriedades necessárias para renderizar o popup com a severidade selecionada (error, success, info e warning).
  */
  constructor(props) {
    super(props);

    this.state = {
      dialogsArray: [],
      dialogsTypes: {
        error: {
          backgroundColor: "#f44336",
          color: "#fff",
          icon: <ErrorIcon style={{ verticalAlign: "middle" }} />
        },
        success: {
          backgroundColor: "#4caf50",
          color: "#fff",
          icon: (
            <CheckCircleIcon style={{ verticalAlign: "middle" }} />
          )
        },
        warning: {
          backgroundColor: "#ff9800",
          color: "#fff",
          icon: <WarningIcon style={{ verticalAlign: "middle" }} />
        },
        info: {
          backgroundColor: "#2196f3",
          color: "#fff",
          icon: <InfoIcon style={{ verticalAlign: "middle" }} />
        }
      }
    };
  }



  /** Função responsável por atualizar o array que contém todos os popups a serem renderizados (dialogsArray).
   * Ela recebe como parametro o array completo do novo dialogsArray já com os dados alterados ou com mais objetos.
   * @function
  */
  updateMessageDialogContext = currentState => {
    this.setState(prevState => {
        return ({ ...prevState, dialogsArray: currentState })
    });

  };


  /** Função responsável por adicionar um novo objeto popup ao array que contém todos os popups a serem renderizados
   * Ela recebe como parametro um objeto com uma ou mais propriedades que cada objeto nesse array pode ter.
   * E em seguida faz um merge com o objeto padrão para adicionar quaisquer parametros que não foram passados pelo objeto à essa função.
   * Essa função também é responsável por alterar o valor do openState para false deste novo objeto quando houver 1 ou mais popups no dialogsArray.
   * @function
  */
  addDialog = (newDialogObject) => {
    const defaultDialog = {
        backdropClick: false,
        openState: true,
        title: '',
        maxWidth: "md",
        fullWidth: true,
        type: null,
        noIcon: false,
        message: '',
        reverseActionButtons: true,
        confirmLabel: "OK",
        hasCloseButton: true,
        cancelLabel: "Cancelar",
        handleConfirm: this.popDialog,
        handleClose: this.popDialog,
    }


    let mergedDialogObject = Object.assign(defaultDialog, newDialogObject)

    let temp_DialogsArray = this.state.dialogsArray;
    if(temp_DialogsArray.length > 0){
      mergedDialogObject.openState = false
    }
    temp_DialogsArray.push(mergedDialogObject);

    this.updateMessageDialogContext(temp_DialogsArray)
    return mergedDialogObject
  }

  addAsyncDialog = (newDialogObject) => {
     return new Promise((resolve, reject) => {
        this.addDialog({
           ...newDialogObject,
           handleConfirm: () => {
             if(newDialogObject && newDialogObject.handleConfirm){
                newDialogObject.handleConfirm()
             }
             this.popDialog()
             resolve(true)
          },
           handleClose: () => {
             if(newDialogObject && newDialogObject.handleClose){
                newDialogObject.handleClose()
             }
             this.popDialog()
             resolve(false)
          },
        })
     })
 }


  /** Função responsável por excluir o primeiro índice do dialogsArray, além de alterar a propriedade openState do proximo índice para true.
   * @function
  */
  popDialog = (event) => {
    let temp_DialogsArray = this.state.dialogsArray;

    //Delete current Dialog
    temp_DialogsArray.splice(0, 1)

    //Change openState of next Dialog to true
    if(temp_DialogsArray.length)
        temp_DialogsArray[0].openState = true;

    this.updateMessageDialogContext(temp_DialogsArray)
  }

  clearAllDialogs = () => this.setState({dialogsArray: []})

  render() {
    let { updateMessageDialogContext, addDialog, popDialog, addAsyncDialog, clearAllDialogs} = this;
    let { dialogsArray, dialogsTypes } = this.state;
    return (
      <MessageDialogContext.Provider
        value={{ dialogsArray, dialogsTypes, updateMessageDialogContext, addDialog, popDialog, addAsyncDialog, clearAllDialogs }}
      >
        {this.props.children}
      </MessageDialogContext.Provider>
    );
  }
}

export { MessageDialogContextProvider, MessageDialogContext };
