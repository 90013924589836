import AbstractError from "./AbstractError";
import { ConnectionError } from "../repository/errorsModels";

export default class apiErrorResponse extends AbstractError {
  constructor(helperData) {
    
    const connectionError = new ConnectionError();
    super(helperData, connectionError, true);
  }
}
