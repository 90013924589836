
/**
 * @description Classe que trás a implementação de event listener. 
 * Neste projeto esta classe funciona como uma classe abstrata, 
 * logo ela não deve ser instanciada, e sim extendida, para exemplos 
 * vide src/service/notifications/errosNotificationCenter
 */
export default class NotificationService {

  constructor() {
    this.observables = [];
  }

  /**
   * @description Registra novo event listenner na instancia desta classe. É Obrigatório o uso da
   * callback fornecida como retorno desta função para evitar memory lake
   * @param {Function} func 
   * @returns {Function} Função utilizada para realizar o unsubscribe da função fornecida.
   */
  subscribe(func) {
    if (func instanceof Function) {
      this.observables.push(func);
      return () => {
        this.observables = this.observables.filter(observable => observable !== func);
      }
    }
    throw new Error("Unexpected parameter at subscribe");
  }

  /**
   * @description Notifica todas as funções que foram previamente registradas com o metodo subscribe
   * @param {String} message Nome do evento
   * @param {Object} data Dados que deseja transmitir
   */
  async notifyAll(message, data = {}) {
    for (const func of this.observables) {
      await func(
        message,
        data
      );
    }
  }

}
