
import React, { Component } from "react";
import {Typography} from '@material-ui/core'
import AbstractError from "./AbstractError";
import {AppStorageError} from '../repository/errorsModels'

class ErrorMessage extends Component{
  render(){
    return (
      <React.Fragment>
        <Typography><b>Cliente:</b> {this.props.data.clientName}</Typography>
        <Typography><b>Hash:</b> {this.props.data.hash}</Typography>
        <Typography><b>Id:</b> {this.props.data.id}</Typography>
      </React.Fragment>
    )
  }
}

/**
 * @description Utilizado para notificar erros que envolvem operações com clientes
 * @example
 * 
 * throw new ClientError({
 *  type: 'error',
 *  title: 'Some title',
 *  clientName: client.nome,
 *  id: client.id,
 *  hash: client.hash,
 *  DATABASE_ID: client.DATABASE_ID,
 * })
 */
export default class ClientError extends AbstractError {
  constructor(helperData) {
    super(
      helperData,
      new AppStorageError()
    ); 
  }

  /**
   * Sobreescrita de metodo para prover popup personalizado
   * @param {*} helperData 
   */
  mountDialogError(helperData) {
    const type = helperData.type || 'error'
    const title = helperData.title || 'Erro: Não foi possivel enviar o cliente ao servidor'
    return [type, {
      message: <ErrorMessage data={helperData}/>,
      title
    }]
  }
  
}
