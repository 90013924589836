import LocalStorage from "../../utils/localStorage";
import { getCompanyData } from '../authentication';
import { PrinterLog } from '../../utils/PrinterLog'
/**
*@description Identificador para variaveis de configuração
* @type {String}
*/

const localStorage = LocalStorage.instance;

export const CONFIG_KEY = "config-key";
const DEFAULT_CONFIG_KEY = {
  isDeveloper: 0,
};

/**
*@description Identificador para variaveis de configuração local, ou seja aquelas cujo o usuario tem a liberdade de gerenciar
* @type {String}
*/
export const LOCAL_CONFIG = "local-config";

/**
*@description Identificador para variaveis de configuração do próprio ambiente, exemplo: Quais telas devem ou não ser exibidas, ou mesmo campos de texto
* @type {String}
*/
export const VARIAVEL_AMBIENTE_KEY = "env-var";
const DEFAULT_LOCAL_CONFIG = {
  
};


export async function getConfigKey(key) {
  let localConfig = await localStorage.getItem(CONFIG_KEY);
  if (!localConfig) {
    localConfig = DEFAULT_CONFIG_KEY;
    await localStorage.setItem(CONFIG_KEY, localConfig);
  }
  return localConfig[key];
}

export async function setConfigKey(key, value) {
  let localConfig = await localStorage.getItem(CONFIG_KEY);
  if (!localConfig) {
    localConfig = DEFAULT_CONFIG_KEY;
  }

  localConfig[key] = value;
  await localStorage.setItem(CONFIG_KEY, localConfig);
}




/**
* @description Getter de configurações locais
* @param {String} key Nome da chave que deseja recuperar
* @example
*
* await getLocalCongfigVar('DownloadType') //returns "complete" por default
*/
export async function getLocalCongfigVar(key) {
  let localConfig = await localStorage.getItem(LOCAL_CONFIG);
  if (!localConfig) {
    localConfig = DEFAULT_LOCAL_CONFIG;
    await localStorage.setItem(LOCAL_CONFIG, localConfig);
  }
  return localConfig[key];
}

/**
* @description Setter das configurações locais
* @param {String} key Nome da chave que deseja alterar
* @param {String || Boolean || Number} Value Valor que deve ser atribuido a chave correspondete
* @example
* await setLocalConfigVar('DownloadType', "partial")
*/
export async function setLocalConfigVar(key, value) {
  let localConfig = await localStorage.getItem(LOCAL_CONFIG);
  if (!localConfig) {
    localConfig = DEFAULT_LOCAL_CONFIG;
  }

  localConfig[key] = value;
  await localStorage.setItem(LOCAL_CONFIG, localConfig);
}
/**
*@description Setter das variaveis de ambiente Segue o mesmo modelo que o setter de configurações locais
*
*/
export async function setEnvVar(key, value) {

  let envs = (await localStorage.getItem(VARIAVEL_AMBIENTE_KEY));
  if (!envs)
    envs = {}
  envs[key] = value;
  await localStorage.setItem(VARIAVEL_AMBIENTE_KEY, envs);
  return envs;
}
/**
*@description Segue o mesmo esquema que o Getter do Getter de configração local
*
*/
export async function getEnvVar(key) {
  let envs = await localStorage.getItem(VARIAVEL_AMBIENTE_KEY);
  if (!envs) return;
  if (!envs[key]) return;
  // if (!envs[key].value) return "";
  if (envs[key].value == "undefined") return "";
  return envs[key].value;
}

export async function clearEnv() {
  await localStorage.removeItem(VARIAVEL_AMBIENTE_KEY);
}


const defaultPropsToPrint = [
  "logradouro",
  "numero",
  "bairro",
]

export async function generateCompanyInvoice() {
  const { nome, endereco } = await getCompanyInfoFromEnvVar()

  const enderecoString = defaultPropsToPrint.map(propName => endereco[propName]).join(", ")
  const estadoCityString = `${endereco.cidade} - ${endereco.estado}`

  const printer = new PrinterLog(32)

  printer.center(nome)
  printer.center(enderecoString)
  printer.center(estadoCityString)

  return printer.value()
}


async function getCompanyInfoFromEnvVar() {
  const envs = await localStorage.getItem(VARIAVEL_AMBIENTE_KEY)

  return {
    nome: envs.EMPRESA_NOME.value,
    endereco: {
      cidade: envs.EMPRESA_CIDADE.value,
      estado: envs.EMPRESA_ESTADO.value,
      bairro: envs.EMPRESA_BAIRRO.value,
      numero: envs.EMPRESA_NUMERO.value,
      logradouro: envs.EMPRESA_LOGRADOURO.value
    }
  }
}