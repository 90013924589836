import AbstractError from "./AbstractError";
import { ConnectionError } from "../repository/errorsModels";

export default class ConnectionErrorServerSide extends AbstractError {
  constructor(helperData = {}) {
    helperData.devHelper =
      "Verificar a url que está tentando acessar no servidor.";
      helperData = Object.assign(
      {
        message:
          "Houve um problema com o servidor, tente novamente em alguns minutos.",
        title: "Falha na conexão",
        type: "warning",
      },
      helperData
    );
    super(helperData, new ConnectionError());
  }
}
