// import styles from "./index.module.css";
import React, {lazy} from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// import RegisterPage from "../pages/Auth/register/index";
// import ScanPage from "../pages/Scan";
import logo from '../logo_maximus_solucoes.png';
const ScanPage = lazy( ()=>import('../pages/Scan') );
const RegisterPage = lazy( ()=>import('../pages/Auth/register/index') );

const styles = theme => ({
  Main_Container:{
      background: 'linear-gradient(to bottom, rgba(0, 147, 184, 1), rgba(0, 122, 152, 0.99) 25%, rgba(0, 33, 42, 0.96) 95%, rgba(0, 19, 23, 0.92))',
      height: '100%',
      color: '#fff',
      overflowX: 'hidden',
      flexWrap: 'nowrap !important',
      '& .Top_Container':{
          paddingTop: '100px',
          paddingBottom: '100px',
          transition: 'all .5s',
      },
      '& .Top_Container_OnLogin':{
          paddingTop: '40px',
          paddingBottom: '50px',
      },
      '& .Top_Container_OnRegister':{
          paddingTop: '0px',
          paddingBottom: '20px',
      },
      "& .Top_Container_OnRegister Main_Title":{
          fontSize: '3.8rem !important',
      },
      '& .Top_Container_OnLogin Main_Title':{
          fontSize: '4rem !important',
      },
  },
  
  Main_Title:{
      fontSize: '5rem !important',
      '& .Small_Title':{
          fontSize: '0.6em',
      }
  }, 
  
  Btns_Container:{
      maxWidth: '270px',
      margin: '10px auto 0 auto !important',
      '& button':{
          width: '100%',
          borderRadius: '30px',
      },
      '& a':{
        textDecoration: 'none',
      }
  },
  
  }
)

class AuthPages extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    const { location, classes } = this.props;
   
    return (
      <>
        <Grid
        container
        direction="column"
        alignItems="center"
        justify="flex-start"
        className={classes.Main_Container}
        >
          <Switch>
            <Route exact path="/scan" component={ScanPage}></Route>
            <Route exact path="/register" component={RegisterPage}></Route>
            <Redirect to="/scan"/>
          </Switch>
        </Grid>
        
      </>
    );
  }
}

export default withStyles(styles)(AuthPages);
