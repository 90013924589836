import AbstractError from "./AbstractError";
import {UnknowError} from '../repository/errorsModels'

export default class UnknownError extends AbstractError {
  constructor(helperData = {}) {
    helperData.devHelper =
      "Erro inesperado, Verifique a stack e anote no trello (ou identifique e crie uma nova classe de erro para trata-lo)";
      helperData = Object.assign(
        {
          message: "Caso veja esta menssagem, notifique o suporte.",
          title: "Oops, ocorreu um erro inexperado",
          type: "error",
        },
        helperData
      );
    super(
      helperData,
      new UnknowError()
    );
  }
}
