import moment from "moment";
import models from "../../repository/models";
import AppStorageError from "../../errorDefinition/AppStorageError";
import BusinessError from "../../errorDefinition/BusinessError";
import ClientError from "../../errorDefinition/ClientError";
import ApiErrorResponse from "../../errorDefinition/ApiErrorResponse";
import LocalStorage from "../../utils/localStorage";
import Fetch from '../../components/Cache';

const localStorage = LocalStorage.instance;

export async function getSchedules(sessionToken, clienteInfoId, empresa, quantidade, pagina, filter){
    try {
        let usuario = await models.Logins.filter((a) => a.clienteInfoId == clienteInfoId && a.empresa == empresa)
        let link = usuario[0].link;
        let URL = `${link}/api-cliente/sync-cliente-agendamentos/${usuario[0].clienteId}/?token=${sessionToken}&quantidade=${quantidade}&pagina=${pagina}`;
        if(filter){
            if(filter.data && moment(filter.data, 'DD/MM/YYYY').isValid()){
                URL+=`&dataRecebimento=${moment(filter.data, 'DD/MM/YYYY').format('YYYY-MM-DD')}`;
            }
            if(filter.tipo){
                if(filter.tipo != "Todas"){
                    URL+=`&tipo=${filter.tipo}`;
                }
            }
            if(filter.ativo == true){
                URL+=`&ativo=${filter.ativo}`;
            }
        }
        const response = await Fetch(URL, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "GET",
            cacheName: "clientCache",
            timeToLive: 10,
        });
        if (!response.ok) {
            return true;
        }
        let data = await response.json();
        return data.data;
    } catch (err) {
        console.error(`Failed to fetch: ${err}`)
    }
}