import Dexie from "dexie";
/**
 * @description Classe que imita o comportamento do localStorage default de window mas que utiliza
 * IndexedDB por baixo dos panos. Nesta classe foi implementado o padrão de projeto sigleton
 * logo é possivel obter um referencia para o "localStorage" diretamente da referencia desta classe
 * vide o exemplo
 * 
 * @example 
 * import LocalStorage from "./LocalStorage"
 * 
 * const localStorage = LocalStorage.instance
 * 
 * let data = await localStorage.getItem(SOME_CONST_KEY)
 */
export default class LocalStorage {
  static #instance;
  static get instance() {
    if (!LocalStorage.#instance) {
      LocalStorage.#instance = new LocalStorage();
    }
    return LocalStorage.#instance;
  }

  constructor() {
    if (LocalStorage.#instance) {
      throw new Error(
        "Alredy have a instance of this class (LocalStorage), please use the static attr instance"
      );
    }
    const DATABASE = new Dexie("localStorage");

    DATABASE.version(1.0).stores({
      storage: "++DATABASE_ID, &name",
    });

    DATABASE.storage.defineClass({
      name: String,
      data: String,
    });
    this.localStorage = DATABASE.storage;
    window.LocalStorage = this;
  }

  /**
   * @description Setter, Caso esta função seja invocada com uma key que já exista, 
   * o valor será sobreescrito
   * @param {String} itemName Chave que deseja settar
   * @param {*} data Dados que deseja armazenar na chave
   */
  async setItem(itemName, data) {
    const item = await this.getItem(itemName);
    
    if (item) {
      await this.removeItem(itemName);
    }
    await this.localStorage.put({
      name: itemName,
      data: JSON.stringify(data),
    });
  }

  /**
   * @description Getter
   * @param {*} itemName Chave identificadora que deseja recuperar
   */
  async getItem(itemName) {
    let item = await this.localStorage
      .where("name")
      .equalsIgnoreCase(itemName)
      .toArray();
    if (!item[0]) return;

    item = JSON.parse(item[0].data);
    return item;
  }

  /**
   * @description Remove um item especifico do armazenamento
   * @param {*} itemName Chave que deseja inserir
   */
  async removeItem(itemName) {
    const itemToRemove = await this.localStorage
      .where("name")
      .equalsIgnoreCase(itemName)
      .toArray();
    await Promise.all(
      itemToRemove.map((item) => this.localStorage.delete(item["DATABASE_ID"]))
    );
  }

  /**
   * @description Limpa todo o armazenamento
   */
  async clear() {
    await this.localStorage.clear();
  }
}
