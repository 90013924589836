import moment from "moment";
import models from "../../repository/models";
import AppStorageError from "../../errorDefinition/AppStorageError";
import BusinessError from "../../errorDefinition/BusinessError";
import ClientError from "../../errorDefinition/ClientError";
import ApiErrorResponse from "../../errorDefinition/ApiErrorResponse";
import LocalStorage from "../../utils/localStorage";
import Fetch, { clearCache } from '../../components/Cache';
const localStorage = LocalStorage.instance;

// export async function check 
export async function createClient(clientData, companyData) {
    try {
        if (!clientData) return;
        let clientObj = {}
        clientObj.nome = clientData.nome;
        clientObj.sexo = clientData.sexo;
        clientObj.dataNascimento = moment(clientData.dataNascimento, "DD/MM/YYYY").toDate();
        clientObj.cep = clientData.cep;
        clientObj.estado = clientData.estado;
        clientObj.cidade = clientData.cidade;
        clientObj.telefone = clientData.telefone;
        clientObj.numero = clientData.numero;
        clientObj.logradouro = clientData.logradouro;
        clientObj.bairro = clientData.bairro;
        clientObj.cpf = clientData.cpf;
        clientObj.email = clientData.email;
        if (!companyData)
            companyData = await localStorage.getItem("companyData");
        let link = companyData.link;
        let clienteId = companyData.clienteId;
        let URL = `${link}/api-cliente/criar/${clienteId}`;

        let response = await fetch(URL, {
            body: JSON.stringify(clientObj),
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
        });

        if (!response.ok) {
            return true;
        }

        let data = await response.json();

        let newLogin = {
            empresa: companyData.empresa,
            clienteInfoId: data.data.cliente.id,
            clienteId: data.data.cliente.ClienteId,
            link: companyData.link,
            token: null
        }
        await models.Logins.create(newLogin);
        return data.data.cliente.id;
    } catch (e) {
        throw e
    }
}

export async function getClientDataFromDB(sessionToken, clientInfoId, companyName) {
    try {
        if (sessionToken && clientInfoId && companyName) {
            let usuario = await models.Logins.filter((a) => a.clienteInfoId == clientInfoId && a.empresa == companyName);
            let link = usuario[0].link;
            let clienteId = usuario[0].clienteId;

            const URL = `${link}/api-cliente/sync-cliente/${clienteId}/${clientInfoId}/?token=${sessionToken}`;

            const response = await Fetch(URL, {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
                cacheName: "clientCache",
                timeToLive: 10,
            });

            if (!response.ok) {
                return false;
            }
            let data = await response.json();
            return data.data;
        } else {
            return false
        }
    } catch (e) {
        console.log(e)
        throw e;
    }
}

export async function createClientSchedule(clientData, schedule){
    
}

export async function sendClientInfoSupport(supportObj, ...others){
    try {
        const [sessionToken, clientInfoId, companyName] = others
        if (sessionToken && clientInfoId && companyName) {
            const { message,
                    dataRecebimento } = supportObj
            let usuario = await models.Logins.filter((a) => a.clienteInfoId == clientInfoId && a.empresa == companyName);
            let link = usuario[0].link;
    
            const URL = `${link}/api-cliente/enviar-notificacao-mensagem/${clientInfoId}?token=${sessionToken}`;
    
            const response = await fetch(URL, {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({
                    mensagem: message,
                    dataRecebimento,
                })
            });
    
            if (!response.ok) {
                return false;
            }
            let data = await response.json();
            return data.data;
        } else {
            return false
        }
    } catch (e) {
        console.log(e)
        throw e;
    }

}

export async function getClientInfoSupports(sessionToken, clientInfoId, companyName){
    try {
        if (sessionToken && clientInfoId && companyName) {
            let usuario = await models.Logins.filter((a) => a.clienteInfoId == clientInfoId && a.empresa == companyName);
            let link = usuario[0].link;
            let clienteId = usuario[0].clienteId;

            const URL = `${link}/api-cliente/buscar-notificacoes/${clientInfoId}/?token=${sessionToken}`;

            const response = await Fetch(URL, {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
                cacheName: "clientCache",
                timeToLive: 10,
            });

            if (!response.ok) {
                return false;
            }
            let data = await response.json();
            return data.data;
        } else {
            return false
        }
    } catch (e) {
        console.log(e)
        throw e;
    }
}

export async function getClientNextAppointment(sessionToken, clientInfoId, empresa) {
    try {

        if (sessionToken && clientInfoId && empresa) {
            let usuario = await models.Logins.filter((a) => a.clienteInfoId == clientInfoId && a.empresa == empresa);

            // usuario = await usuario

            if (!usuario || !usuario.length) {
                throw new Error('Usuário não encontrado!');
            }

            let link = usuario[0].link;
            let clienteId = usuario[0].clienteId;
            const URL = `${link}/api-cliente/agendamento/proximo/${clienteId}?token=${sessionToken}`;

            const response = await Fetch(URL, {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
                cacheName: "clientCache",
                timeToLive: 10,
            });

            if (!response.ok) {
                throw new Error('Erro ao recuperar informação de Próximo Agendamento');
            }
            let data = await response.json();

            if (data.error) {
                throw new Error(data.msg);
            }

            return data.data;
        } else {
            throw new Error('Empresa, SessãoToken e clienteInfoid devem ser passados por parâmentro');
        }

    } catch (e) {
        console.log(e)
    }
};

export async function saveClientEditData(clientData, sessionToken, clientInfoId) {
    try {

        let usuario = await models.Logins.filter((jrr) => jrr.token == sessionToken);
        let link = usuario[0].link;
        let clienteId = usuario[0].clienteId;
        const URL = `${link}/api-cliente/editar-cliente/${clientInfoId}/?token=${sessionToken}`;
        const response = await fetch(URL, {
            body: JSON.stringify(clientData),
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST"
        });

        // Clear cache that contains user data from the dataBase
        await clearCache('clientCache', `${link}/api-cliente/sync-cliente/${clienteId}/${clientInfoId}/?token=${sessionToken}`)

        if (!response.ok) {
            return true;
        }

        let resp = await response.json();

        return resp;

    } catch (err) {
        console.error(`Failed to fetch: ${err}`)
        throw err;
    }
}

export async function updateModelToken(newToken) {
    try {
        let usuarios = await models.Logins.getAll();
        if (!usuarios.length)
            return
        let proms = usuarios.map(async usuario => {
            let link = usuario.link;
            const URL = `${link}/api-cliente/push/${usuario.clienteInfoId}/?token=${usuario.token}`;
            const response = await fetch(URL, {
                body: JSON.stringify({
                    token: newToken
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "post"
            });

            if (!response.ok) {
                return false;
            }

            return true;
        });

        return await Promise.all(proms);
    } catch (e) {
        console.log(e)
        throw e;
    }
}


export async function getClientEditions(sessionToken, clientInfoId, companyName, filter){
    try {
        if (sessionToken && clientInfoId && companyName) {
            let usuario = await models.Logins.filter((a) => a.clienteInfoId == clientInfoId && a.empresa == companyName);
            let link = usuario[0].link;
            let clienteId = usuario[0].clienteId;

            const URL = `${link}/api-cliente/edicoes/${clienteId}/?token=${sessionToken}&dataInicio=${filter.dataInicio}&dataFim=${filter.dataFim}`;

            const response = await Fetch(URL, {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
                cacheName: "clientCache",
                timeToLive: 10,
            });

            if (!response.ok) {
                return false;
            }
            let data = await response.json();
            return data.data;
        } else {
            return false
        }
    } catch (e) {
        console.log(e)
        throw e;
    }
}

// export function latinize(name) {
//   if (!name) return;

//   var map = {
//     "-": " ",
//     "-": "_",
//     a: "á|à|ã|â|À|Á|Ã|Â",
//     e: "é|è|ê|É|È|Ê",
//     i: "í|ì|î|Í|Ì|Î",
//     o: "ó|ò|ô|õ|Ó|Ò|Ô|Õ",
//     u: "ú|ù|û|ü|Ú|Ù|Û|Ü",
//     c: "ç|Ç",
//     n: "ñ|Ñ",
//   };

//   // name = name.toLowerCase();

//   for (var pattern in map) {
//     name = name.replace(new RegExp(map[pattern], "g"), pattern);
//   }

//   return name;
// }
