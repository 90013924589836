import * as moment from "moment";
import { getConfigKey } from '../../service/config';
import { getCompanyData } from '../../service/authentication';
import apiErrorResponse from "../../errorDefinition/ApiErrorResponse";

export default async function cacheFunction(url, options) {
  const { timeToLive, ...fetchOptions } = options;
  
  let cacheName = (await getCompanyData()).empresa;

  const isCacheValid = await cacheIsValid(cacheName, timeToLive);
  const cache = await getDataFromCache(url, cacheName);


  if (isCacheValid && cache) return cache;

  try {
    const [parsedInfo, response] = await Fetch(url, fetchOptions);

    await cacheData(url, response, cacheName);

    if (!(isCacheValid && cache)) {
      return parsedInfo;
    }
  } catch (error) {
    throw error;
  }
}

async function Fetch(url, options) {
  let response;
  let clonedResponse;
  try {
    response = await fetch(url, options);
    clonedResponse = response.clone();
  } catch (error) {
    throw new apiErrorResponse({
      message: error.message,
      title: "Oops, houve um problema",
      type: "error",
      method: `fetch:${url}`,
    });;
  }

  if (response.status > 400) {
    throw new Error("Erro code: " + response.status);
  }

  if (!response.ok) {
    throw new apiErrorResponse({
      message: response.text,
      title: "Houve um problema no servidor",
      type: "error",
      method: `fetch:${url}`,
    });;
    
  }
  return [response, clonedResponse];
}

async function getDataFromCache(url, cacheName) {
  const cache = await caches.open(cacheName);

  const cachedData = await cache.match(url, { ignoreVary: true });
  if (!cachedData) return
  return cachedData;
}

async function cacheData(url, data, cacheName) {
  const cache = await caches.open(cacheName);
  await cache.put(url, data);

  const now = new Date();
  localStorage.setItem(cacheName, JSON.stringify(now));
}

async function cacheIsValid(cacheName, timeToLive) {

  const limitDate = moment().subtract(timeToLive, "days");
  const cacheInfoString = localStorage.getItem(cacheName);
  const isDev = await getConfigKey('isDeveloper');
  const cacheDate = moment(JSON.parse(cacheInfoString));
  
  return (cacheInfoString && !isDev && cacheDate.isSameOrAfter(limitDate))

}
 
export async function clearCache(cacheName, url){
  if(!cacheIsValid(cacheName)) return
  const cache = await caches.open(cacheName);
  await cache.delete(url)
}