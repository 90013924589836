import ServiceNotification from "../service/notifications/errosNotificationCenter";

/**
 * @description Classe abstrata para reunião de comportamento padrão entre os erros que podem ocorrer
 * Principal finalidade é prover a facilidade de lançamento de erros para a view usando popops 
 * que é feito através da união desta classe e as demais que a estendem e a classe !rrosNotificationCenter
 * que provê a comunicação entre a view e as services
 */
export default class AbstractError extends Error {
  constructor(helperData = {}, dbRef) {
    super(helperData.message);
    helperData.errorClassName = this.constructor.name;
    if(!('showToUser' in helperData)) helperData.showToUser = true
    this.helperData = helperData;

    
    if (helperData.showToUser) {
      const [type, infos] = this.mountDialogError(helperData);
      ServiceNotification.instance.notifyAll(type, infos).then();
    }

    if(helperData.error){
      helperData.error = {
        name: helperData.error.name,
        stack: helperData.error.stack,
        message: helperData.error.message,
      }
    }
    dbRef.store(helperData).then();
  }

  /**
   * @description Utilitario default para personalização da notificação do erro. Util caso deseje
   * mudar a formatação padrão ou mesmo inserir uma jsx personalizado dentro do popup de notificação
   * para exemplos veja src/errorDefinition/ClientError.js Caso a personalização seja necessaria. 
   * sobreescreva este método
   * @param {*} helperData 
   */
  mountDialogError(helperData) {
    const { type, ...others} = helperData;
    return [type, others];
  }
}
