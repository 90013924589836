import DataAccess  from '../DataAccessStrategy'

export default class Validator extends DataAccess{
  constructor(){
    super('Validator', {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      namespace: String,
      validator: String,
    })
  }
}
