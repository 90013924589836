import React, { Component, createContext } from "react";
import {setLocalConfigVar, LOCAL_CONFIG} from '../service/config/index'
import LocalStorage from '../utils/localStorage'
import AppStorageError from "../errorDefinition/AppStorageError";

import {MessageDialogContext} from "../contexts/MessageDialogContext";

const localStorage = LocalStorage.instance
const ConfigContext = createContext({
    config: [],
});

/**
 * Contexto utilizado na pagina de configurações.
*/
class ConfigContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClickSaveConfig:this.onClickSaveConfig,
      getConfigData:this.getConfigData
    };
  }

  /**Função responsável por desabilitar as transições do App.
    * @function
    * @param {boolean} enable Boleano que indicara o estado a ser setado.
  */
  handleTransitionOnOff(enable){
    let htmlTag = document.getElementById('html-element')
    if(enable){
      htmlTag.classList.remove('disableTransitions');
    }else{
      htmlTag.classList.add('disableTransitions');
    }
  }

  /**Função responsável por salvar no localStorage do dexie as configs escolhidas pelo usuário.
    * @function
    * @param {string} key Indicará a propriedade ser alterada.
    * @param {boolean} data Indicará para qual estado a proriedade se tornará.  
  */
  onClickSaveConfig = async(key,data) =>{
      try{
        await setLocalConfigVar(key,data)
        if(key == 'EnableTransitions')
          this.handleTransitionOnOff(data)
      }catch(error){
        new AppStorageError({
          message: error.message,
          title: "Falha ao salvar",
          type: "error",
          method: 'SaveConfig',
        })
      }
  }

  /**Função responsável buscar as configurações no localStorage do Dexie.
    * @function
  */
  getConfigData = async () =>{
    try{
      return await localStorage.getItem(LOCAL_CONFIG)
    }catch(error){
      new AppStorageError({
        message: error.message,
        title: "Falha ao salvar configuração!",
        type: "error",
        method: 'SaveConfig',
      })
    }
  }
 
  render() {
    return (
      <MessageDialogContext.Consumer>
            {
               (context) => {
                  if(this.context !== context) this.context =context
                  return(
                      <ConfigContext.Provider value={this.state}>
                        {typeof this.props.children === "function"
                           ? this.props.children()
                           : this.props.children}
                      </ConfigContext.Provider>
                  )
               }
            }
      </MessageDialogContext.Consumer>
    );
  }
}

export { ConfigContext, ConfigContextProvider };
