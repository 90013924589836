import React, { Component } from "react";
import SyncNotificationCenter from "../service/notifications/syncNotificationCenter";
import UnknownError from "../errorDefinition/UnknownError";
import LocalStorage from '../utils/localStorage'
// import {ROUTE_OPENING} from '../service/route'
import BusinessError from "../errorDefinition/BusinessError";

/**
 * @description Classe utilitária para fornecer
 * metodos convenientes para sincronização
 * entre os contextos e o banco de dados
 * do app
 *
 * @param {Object} props Propriedades padrão dos componentes de react
 * @param {Array} allowedModels Conjunto de strings para manipulação dos
 * estados internos no app
 *
 * @example
 * const OBSERVED_MODELS = ['Cidade', 'Cliente', 'Bairro'];
 * class YourClassName extends AbstractContext {
 *  constructor(props){
 *    super(props, ALLOWED_MODELS)
 *    //your code
 *  }
 * }
 */
export class AbstractContext extends Component {
  constructor(props, observedModels) {
    super(props);
    this.observedModels = observedModels;
  }

  componentDidMount() {
    this.unsubscribe = SyncNotificationCenter.instance.subscribe(
      this.shouldCallObservable
    );
  }
  componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidCatch(error) {
    new UnknownError({
      type: "error",
      message: error.message,
      title: "Um erro inexperado ocorreu",
      error: error,
    });
  }

  shouldCallObservable = (eventName, changedModels) => {
    if (eventName !== "activeSync") return;
    const isObserved = changedModels.some((modelName) =>
      this.observedModels.some(
        (observedModelName) => modelName == observedModelName
      )
    );

    if (isObserved) this.observable(eventName, changedModels);
  };

  observable(eventName, changedModels) {
    throw new Error(
      "Você deve implementar este médoto na classe que extende AbstractContext"
    );
  }

  async notifyModelChange(eventName, changedModel) {
    await SyncNotificationCenter.instance.notifyAll(eventName, [changedModel]);
  }
}
