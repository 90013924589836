import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import Switch from '@material-ui/core/Switch';
import { Grid } from '@material-ui/core';
import models from '../../repository/models'
// import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import LocalStorage from '../../utils/localStorage';
import Fetch from '../../components/Cache';

const localStorage = LocalStorage.instance;

export async function getStatements(sessionToken, clienteInfoId, company, quantidade, page, filter) {
    try {
        let usuario = await models.Logins.filter((a) => a.clienteInfoId == clienteInfoId && a.empresa == company);
        let clienteId = usuario[0].clienteId;
        let link = usuario[0].link;
        
        let URL = `${link}/api-cliente/sync-cliente-extratos/${clienteId}/?token=${sessionToken}`;
        if (quantidade) {
            URL += `&quantidade=${quantidade}`
        }

        if (page >= 0) {
            URL += `&pagina=${page}`
        }

        if (filter) {
            if(filter.data && moment(filter.data, 'DD/MM/YYYY').isValid()){
                URL +=`&dataRealizacao=${moment(filter.data, 'DD/MM/YYYY').format('YYYY-MM-DD')}`;
            }
            if (filter.tipo && filter.tipo != "Todas") {
                URL += `&tipoAtividade=${filter.tipo}`;
            }
        }

        const response = await Fetch(URL, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "GET",
            cacheName: "clientCache",
            timeToLive: 10,
        });

        if (!response.ok) {
            return true;
        }
        let data = await response.json();
        return data.data
    } catch (err) {
        console.error(`Failed to fetch: ${err}`)
    }
}

export async function getBalances(qnt=8) {
    try {
        let infoSessao = await localStorage.getItem("clientSession");
        let vendas = await getStatements(infoSessao.token, infoSessao.clientInfoId, infoSessao.empresa, qnt, 0);
        if(!infoSessao || !vendas) return null
        let saldos = vendas.sortedRecebimentosVenda.map((a) => parseFloat(a.saldo));
        let datas = vendas.sortedRecebimentosVenda.map((a) => moment(a.dataRealizacao).format("YYYY-MM-DD"));
        let saida = {};
        saida = {
            saldos,
            datas
        };

        return saida;
    } catch (err) {
        console.error(`Failed to fetch: ${err}`)
    }
}