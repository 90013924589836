import React from "react";
import ErrosNotificationCenter from "../../service/notifications/errosNotificationCenter";
import { MessageDialogContext } from "../../contexts/MessageDialogContext";

import MessageDialog from "./MessageDialog";
/** Componente que gerencia a renderização de todos os componentes MessageDialog  */

const ERROR_NAME_BLACKLIST = ["InvalidAccessToken", "SessionDataLostError"];
class MessageDialogManager extends React.Component {
  /**  @property {array} context.dialogsArray Array que contém os dados de todos os popups armazenados no contexto de MessageDialog
   */

  constructor(props) {
    super(props);
    this.unsubscribe = ErrosNotificationCenter.instance.subscribe(
      this.observable
    );
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  observable = async (type, info) => {
    if (
      ERROR_NAME_BLACKLIST.some((className) => className == info.errorClassName)
    )
      return;
    this.context.addDialog({
      type,
      ...info,
    });
  };

  render() {
    let context = this.context;
    return (
      <>
        {context.dialogsArray.map((dialog, index) => {
          return <MessageDialog key={index} dialogData={dialog} />;
        })}
      </>
    );
  }
}
MessageDialogManager.contextType = MessageDialogContext;

export default MessageDialogManager;
