import React, { createContext } from "react";
import AppStorageError from "../errorDefinition/AppStorageError";
import BusinessError from "../errorDefinition/BusinessError";
import {AbstractContext} from './AbstractContext'
import {MessageDialogContext} from "../contexts/MessageDialogContext";
import LocalStorage from '../utils/localStorage';
import { getCities, getStates } from '../service/company'

const localStorage = LocalStorage.instance
const NeighborhoodContext = createContext({
    neighborhood: [],
    cities:[],
    states:[]
  });

const success = (context,message)=>( context.addAsyncDialog({
    message: message,
    title: 'Sucesso!',
    type: 'success',
    hasCloseButton: false,
}))

/**
 * Contexto utilizado na pagina de Novo cliente, Ver Cliente e Perfil.
*/
const OBSERVED_MODELS = []
class NeighborhoodContextProvider extends AbstractContext {
    constructor(props) {
        super(props, OBSERVED_MODELS);
        this.state = {
            isLoading:false,
            neighborhood:[],
            cities:[],
            states:[],
            fetchCities: this.fetchCities,
            fetchStates: this.fetchStates,
        }
    }


    componentDidMount(){
        super.componentDidMount();
        this.fetchStatesAndCities()
    }

    fetchStatesAndCities = async () => {
        let states = await this.fetchStates();
        if(this.state.states != states){
            this.setState({states: states})
        }
        // this.updateSelf('states')
        // let cities = await thi
    }
    fetchStates = async () => {
        //DADOS MOCADOS
        let estados = []
        let link = await localStorage.getItem('companyData');
        let estadosResp = await getStates(link && link.link);
        if (estadosResp) {
            estados = estadosResp.estados.map((item) => ({ id: item, nome: item }))
        }
        // this.setState({ estadosArray: estados });
        return estados;
        };

    fetchCities = async (province) => {
        let cidades = [];
        let link = await localStorage.getItem('companyData');
        // if(!this.state.estado.value) return;
        let cidadesResp = await getCities(link && link.link, province || this.state.estado.value);
        if (cidadesResp) {
            cidades = cidadesResp.cidades.map((item) => ({ id: item, nome: item }))
        }
        // this.setState({ cidadesArray: cidades });
        return cidades;
    }

    updateSelf = async(stateName) =>{
        this.setState({isLoading:true})
        let state = {...this.state}
        state[stateName].value = await state[stateName].class.getAll()
        this.setState({ state ,isLoading:false})
    }

    render() {
        let { } = this.state;
        let { fetchCities, fetchStates} = this
        return (
            <MessageDialogContext.Consumer>
            {
               (context) => {
                  if(this.context !== context) this.context =context
                  return(
                     <NeighborhoodContext.Provider value={{fetchCities, fetchStates}}>
                        {typeof this.props.children === "function"
                           ? this.props.children()
                           : this.props.children}
                        </NeighborhoodContext.Provider>
                  )
               }
            }
            </MessageDialogContext.Consumer>
        );
    }


}

export { NeighborhoodContext, NeighborhoodContextProvider };