import Dexie from 'dexie';

export default class DexieCollections {
  constructor(db_name){
    if(!db_name) throw new Error('É necessario informar o nome do banco')
    const DATABASE = new Dexie(db_name);
    DATABASE.version(1.0).stores({
      Logins: '++DATABASE_ID, id, empresa, clienteInfoId, clienteId, link, hash, token',
      Notifications: '++DATABASE_ID, id, empresa, titulo, body, url, time, img',
    })
    DATABASE.version(1.1).stores({
      Notifications: '++DATABASE_ID, id, company, title, body, url, time, img, read'
    })
    DATABASE.version(1.2).stores({
      Validator: '++DATABASE_ID, id',
    })
    DATABASE.version(1.3).stores({
      Notifications: '++DATABASE_ID, id, company, title, body, url, time, img, read, active'
    })
    DATABASE.version(2.0).stores({
      Notifications: '++DATABASE_ID, id, company, title, body, url, time, img, read, active'
    })
    DATABASE.version(2.1).stores({
      Notifications: '++DATABASE_ID, id, company, title, body, ClienteInfoSuporteId, url, time, img, read, active'
    })
    DATABASE.version(2.2).stores({
      Notifications: '++DATABASE_ID, id, company, title, body, question, url, time, img, read, active'
    })
    


    DATABASE.generateUniqId = this.generateUniqId
    DexieCollections.database = DATABASE;
  }


  static get database(){
    if(!window.DATABASE) throw new Error('Dexie database definition must be initiatate before access it.');
    return window.DATABASE;
  }

  static set database(databaseArg){
    if(window.DATABASE) return;
    window.DATABASE = databaseArg;
  }

  generateUniqId() {
    const length = 10;
    const array = crypto.getRandomValues(new Uint32Array(length));
    const randomNumberPre = Math.floor(Math.random() * length);
    const randomNumberSub = Math.floor(Math.random() * length);
    return (-1) * ((new Date()).getTime() + (array[randomNumberPre] || randomNumberPre) + (array[randomNumberSub] || randomNumberSub));
  }
}
