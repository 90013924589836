import AbstractError from "./AbstractError";
import { ConnectionError } from "../repository/errorsModels";

export default class ConnectionErrorFetchFaild extends AbstractError {
  constructor(helperData = {}) {
    helperData.devHelper = "Provavelmente é problema com a internet do cliente";
    helperData = Object.assign(
      {
        message: "Verifique sua conexão com a internet e tente novamente.",
        title: "Falha na conexão",
        type: "warning",
      },
      helperData
    );
    super(
      helperData,
      new ConnectionError()
    );
  }
}
