import packageJSON from "../../package.json";
import DexieDataAccessObjectInterface from "./abstractClasses/DexieDataAccessObjectInterfaces";

const DatabaseAccessStrategy = strategy => {
  const strategies = {
    dexie: () => DexieDataAccessObjectInterface,
    default: () => {
      throw new Error("Banco de dados informado não encontrado");
    }
  };
  return (strategies[strategy] || strategies["default"])();
};

export default DatabaseAccessStrategy(packageJSON["maximus-config"].odm);
