import React, {lazy} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import {Route, Switch, Redirect } from 'react-router-dom'
import { getConfigKey } from '../service/config'

const HomePage = lazy( ()=>import('../pages/Home') );
const ProfilePage = lazy( ()=>import('../pages/Profile') );
const SchedulesPage = lazy( ()=>import('../pages/Scheduling') );
const StatementsPage = lazy( ()=>import('../pages/BankStatements') );
const CompaniesPage = lazy( ()=>import('../pages/Companies') );
const NotificationsPage = lazy( ()=>import('../pages/Notifications') );
const SupportPage = lazy( ()=>import('../pages/Support') );
const RegisterPage = lazy( ()=>import('../pages/Auth/register') );
const ScanPage = lazy( ()=>import('../pages/Scan') );
const LGPDPage = lazy( ()=>import('../pages/LGPD') );

function AppRouter(props) {

    return (
        
        <Switch>
            <Route exact path="/" component={HomePage}/>
            <Route exact path="/profile" component={ProfilePage}/>
            <Route exact path="/schedules" component={SchedulesPage}/>
            <Route exact path="/support" component={SupportPage}/>
            <Route exact path="/statements" component={StatementsPage}/>
            <Route exact path="/company" component={CompaniesPage}/>
            <Route exact path="/notifications" component={NotificationsPage}/>
            <Route exact path="/register" component={RegisterPage}/>
            <Route exact path="/lgpd" component={LGPDPage}/>
            <Route exact path="/scan" component={ScanPage}/>
            <Redirect to="/"/>
        </Switch>
    );


}

export default AppRouter;