import AbstractService from '../AbstractService';
import ApiErrorResponse from "../../errorDefinition/ApiErrorResponse";
import LocalStorage from "../../utils/localStorage";
import models from "../../repository/models";
import Fetch from '../../components/Cache';

const localStorage = LocalStorage.instance;

export async function getCompanyDataFromDB(){
    try {
        let currentClientSession = await localStorage.getItem("clientSession");
        if(!currentClientSession) return null
        let user = await models.Logins.filter((a) => a.token == currentClientSession.token);
        // user = await user
        let link = user[0].link;
        const URL = `${link}/api-cliente/sync-funcionarios-rota/${user[0].clienteId}?token=${currentClientSession.token}`;
        const response = await Fetch(URL,  {
            headers: {
                "Content-Type": "application/json",
            },
            method: "GET",
            cacheName: "clientCache",
            timeToLive: 10,
        });
        if (!response.ok) {
            throw new ApiErrorResponse({
                message: 'Ocorreu um erro ao buscar os dados do cliente, caso o problema persista entre em contato com o suporte.',
                title: 'Oops, houve um problema',
                type: 'error',
                hint: "Algum erro ao buscar os dados do cliente e empresa.",
                method: 'getHomeScreenClientData',
                hasCloseButton: false,
            });
        }
        let data = await response.json();
        return data.data;
    } catch (err) {
        console.error(`Failed to fetch: ${err}`)
    }
}

export async function getStates(link){
    try {
        const linkk = link ? link : await localStorage.getItem('clientSession').link;
        if(!linkk) return
        const URL = `${linkk}/api-cliente/obter-estados`;
        const response = await Fetch(URL,  {
            headers: {
                "Content-Type": "application/json",
            },
            method: "GET",
            cacheName: "clientCache",
            timeToLive: 10,
        });
        if (!response.ok) {
            return true;
        }
        let data = await response.json();
        return data.data;
    } catch (e) {
        console.error(`failed to fetch: ${e}`);
    }
}

export async function getCities(link, estado){
    try {
        const linkk = link ? link : await localStorage.getItem('clientSession').link;

        const URL = `${linkk}/api-cliente/obter-cidades/${estado}`;
        const response = await Fetch(URL,  {
            headers: {
                "Content-Type": "application/json",
            },
            method: "GET",
            cacheName: "clientCache",
            timeToLive: 10,
        });
        if (!response.ok) {
            return true;
        }
        let data = await response.json();
        return data.data;
    } catch (e) {
        console.error(`failed to fetch: ${e}`);
    }
}