import firebase from "firebase";
import LocalStorage from './utils/localStorage';
import history from './router/History';
import { updateModelToken } from './service/client'

const localStorage = LocalStorage.instance;

let config = {
    apiKey: "AIzaSyAe50XPcwL5ryY1P_LlEBVN_IfDqJy6WBo",
    authDomain: "maximuscesta.firebaseapp.com",
    databaseURL: "https://maximuscesta.firebaseio.com",
    projectId: "maximuscesta",
    storageBucket: "maximuscesta.appspot.com",
    messagingSenderId: "8370143979",
    appId: "1:8370143979:web:fde1f81a4b8bbb0b",
    showNotificationsWhenInForeground: true
};
export async function initializePush(registration) {
    if (!firebase.apps.length) {
        firebase.initializeApp(config)
        const messaging = firebase.messaging();
        registration && messaging.useServiceWorker(registration);
        const Token = messaging.requestPermission()
            .then(() => {
                return messaging.getToken();
            })
            .then(async token => {
                // Set token in localStorage
                await localStorage.setItem('fcm-token', token)
                await updateModelToken(token)

                return token
                //you probably want to send your new found FCM token to the
                //application server so that they can send any push
                //notification to you.
            })
            .catch(error => {
                if (error.code === "messaging/permission-blocked") {
                    console.log("Please Unblock Notification Request Manually");
                } else {
                    console.log("Error Occurred", error);
                }
            });
        messaging.onTokenRefresh(() => {
            messaging.getToken()
                .then(async newToken => {
                    await localStorage.setItem('fcm-token', newToken)
                    await updateModelToken(newToken)

                    return newToken
                })
                .catch(e => {
                    console.log(e)
                })
        })
        return await Token;
    }
}