import models from '../../repository/models';
import moment from 'moment';
import ClientError from "../../errorDefinition/ClientError";
import LocalStorage from "../../utils/localStorage";
import { unregister } from '../../serviceWorker';
import Fetch, { clearCache } from '../../components/Cache';

const localStorage = LocalStorage.instance;

export async function getCompanyData() {
    try {
        let company = await localStorage.getItem("companyData");
        return company;
    } catch (e) {
        console.error(`Failed to fetch: ${e}`)
    }
}

export async function verifyCompanyData(clientSession) {
    let usuario = await models.Logins.filter((a) =>
        (a.clienteInfoId == clientSession.clientInfoId)
        &&
        a.empresa == clientSession.empresa
        &&
        a.token == clientSession.token);
    return usuario.length;
}

export async function isAuthenticated() {

    if (await getClientSession()) return true;
    let allLogins = await models.Logins.getAll();
    let promises = allLogins.map(async login => {
        if (verifyCompanyData(login)) {
            await setClientCompanyData(login);
            await setClientSession({ token: login.token, empresa: login.empresa, clientInfoId: login.clienteInfoId })
            return true;
        }
        return false;
    })
    let arrayIsAuthenticated = await Promise.all(promises);
    return arrayIsAuthenticated.some(a => a)
}

export async function createSession(idClienteInfo) {
    try {
        let clienteBanco = await models.Logins.getAll();
        const dadosEmpresa = await getCompanyData();

        const link = dadosEmpresa.link;

        clienteBanco = clienteBanco.filter((a) => a.clienteInfoId == idClienteInfo && a.token == null)[0]

        URL = `${link}/api-cliente/criar-sessao`

        // let sessao = await getClientSession();
        let requisicaoDeSessao = {}
        // if (!sessao || !sessao.token){
        //     return
        // }
            // requisicaoDeSessao.token = sessao.token;
            requisicaoDeSessao.id = idClienteInfo;
            const response = await Fetch(URL, {
                body: JSON.stringify(requisicaoDeSessao),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST"
            });

        if (!response.ok) {
            return false;
        }

        let data = await response.json();

        let clientSession = {
            token: data.token,
            clientInfoId: data.idClienteInfo,
            empresa: dadosEmpresa.empresa
        };

        await localStorage.setItem("clientSession", clientSession);
        // let loginModel = await models.Logins.filter( login => login.clienteInfoId == data.idClienteInfo && login.token == null)[0]
        let newLoginModelObj = {
            ...clienteBanco,
            token: data.token
        }
        // Update Logins model to store token
        clienteBanco && await models.Logins.put(newLoginModelObj)

    } catch (error) {
        console.log(error)
        return false;
    }
}

export async function getClientSession() {
    try {
        let dadosSessao = await localStorage.getItem("clientSession");
        return dadosSessao;
    } catch (e) {
        console.error(`Failed to fetch: ${e}`)
    }
}

export async function setClientSession(clientSession) {
    try {
        if (clientSession.token && clientSession.empresa && clientSession.clientInfoId) {
            await localStorage.setItem("clientSession", clientSession);
            return true
        }
        return false

    } catch (e) {
        console.log(e)
    }

}
export async function logout() {
    try {
        await localStorage.removeItem('clientSession');
        await localStorage.removeItem('companyData');
        // await models.Notifications.removeAll();
        // await models.Logins.removeAll();
        unregister();
        return true

    } catch (e) {
        console.log(e)
    }
}

export async function setClientCompanyData(companyData) {
    try {
        let ls_companyData = await localStorage.getItem("companyData")
        if (companyData.link && companyData.empresa && companyData.clienteId) {
            if (!ls_companyData || ls_companyData.link != companyData.link || ls_companyData.empresa != companyData.empresa || ls_companyData.clienteId != companyData.clienteId) {
                await localStorage.setItem("companyData", companyData);
            }
            return true
        }
        return false
    } catch (e) {
        console.log(e)
    }

}

export async function setClientValidators() {
    const dadosEmpresa = await getCompanyData();

    const link = dadosEmpresa.link;

    const URL = `${link}/api-cliente/validators`;

    const response = await Fetch(URL, {
        headers: {
            "Content-Type": "application/json",
        },
        method: "GET",
        cacheName: "clientCache",
        timeToLive: 10,
    });

    if (!response.ok) {
        return true;
    }

    let data = await response.json();

    for (let i in data.validators) {
        let val = {
          id: i,
          namespace: i,
          validator: data.validators[i],
        };
        await models.Validator.create(val, false);
      }

    return {
        validators: data.validators,
    };
}

export async function authenticateByQR(data) {
    if (data.includes('maximussolucoes.com.br') || data.includes('localhost')) {
        let urlData = new URL(data);
        let urlParams = new URLSearchParams(urlData.search);
        // Verifica se existe link
        let link = urlParams.get('link') ? urlParams.get('link') : null;
        if (link) {
            let companyData = {
                "link": urlParams.get('link'),
                "empresa": urlParams.get('empresa'),
                "clienteId": urlParams.get('clienteId')
            };
            // Grava no localstorage
            await setClientCompanyData(companyData)
        }

        return !!link;

    } else {
        return false
    }
}