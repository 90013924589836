import DataAccess from "../DataAccessStrategy";

export default class Logins extends DataAccess {
  constructor() {
    super("Logins", {
      DATABASE_ID: Number, 
      id: Number, 
      empresa: String, 
      clienteInfoId: String,
      clienteId: String,
      link: String, 
      token: String,
      // hash: String,
    });
  }
}
