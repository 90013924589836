import React, { createContext } from "react";
import AppStorageError from "../errorDefinition/AppStorageError";
import BusinessError from "../errorDefinition/BusinessError";
import {AbstractContext} from './AbstractContext';
import {MessageDialogContext} from "../contexts/MessageDialogContext";
import LocalStorage from '../utils/localStorage';
import { getCities, getStates } from '../service/company';
import {getClientInfoSupports, sendClientInfoSupport} from '../service/client';
import moment from 'moment'

const localStorage = LocalStorage.instance
const NotificationsContext = createContext({

  });

const success = (context,message)=>( context.addAsyncDialog({
    message: message,
    title: 'Sucesso!',
    type: 'success',
    hasCloseButton: false,
}))

/**
 * Contexto utilizado na pagina de Novo cliente, Ver Cliente e Perfil.
*/
const OBSERVED_MODELS = []
class NotificationsContextProvider extends AbstractContext {
    constructor(props) {
        super(props, OBSERVED_MODELS);
        this.state = {
            isLoading:false,
            allNotifications: []
        }
    }

    componentDidMount(){
        super.componentDidMount();
        this.fetchClienteInfoNotifications()
    }

    fetchClienteInfoNotifications = async () => {
        // (sessionToken, clientInfoId, companyName)
        let clientSession = await localStorage.getItem("clientSession");
        if(clientSession){
            let notifications = await getClientInfoSupports(clientSession.sessionToken, clientSession.clientInfoId, clientSession.companyName)
        }
    }
    
    getClientServerNotifications = async () => {
        let clientSession = await localStorage.getItem("clientSession");
        if(clientSession){
            let notifications = await getClientInfoSupports(clientSession.token, clientSession.clientInfoId, clientSession.empresa)
        }
        
    }

    sendClientServerNotifications = async (notificationObj = {}) => {
        let clientSession = await localStorage.getItem("clientSession");
        if(clientSession){
            notificationObj.dataRecebimento = moment().toDate()
            let resp = await sendClientInfoSupport(notificationObj, clientSession.token, clientSession.clientInfoId, clientSession.empresa)
            return resp;
        }
        
    }

    updateSelf = async(stateName) =>{
        this.setState({isLoading:true})
        let state = {...this.state}
        state[stateName].value = await state[stateName].class.getAll()
        this.setState({ state, isLoading:false})
    }

    render() {
        const { sendClientServerNotifications } = this
        return(
            <NotificationsContext.Provider value={{...this.state, sendClientServerNotifications}}>
            {typeof this.props.children === "function"
                ? this.props.children()
                : this.props.children}
            </NotificationsContext.Provider>
        )
    }

}
NotificationsContextProvider.contextType = MessageDialogContext

export { NotificationsContext, NotificationsContextProvider };