import ConnectionErrorTimeOut from '../errorDefinition/ConnectionErrorTimeOut'


let controller = new AbortController();
const nativeFetch = window.fetch;
/**
 * @description Sobreescrita da função default do js para prover o comportamento necessario.
 * Esta sobreescrita fornece a capacidade de cancelar todas as requisições fetch que foram 
 * realizadas depois que uma qualquer tenha sofrido timeout ou ocorrido um erro, evitando 
 * desta forma requisições desnecessarias e liberação 
 */
window.fetch = async function fetchPolyfill(url, options = {}){
  const signal = controller.signal;
  let timeoutId;
  const timeoutProm = () => new Promise( (resolve, reject) => {
    timeoutId = setTimeout(() => reject(new ConnectionErrorTimeOut()), Math.min(options.timeout || 30000, 30000) )
    console.log("TIMEOUT",options.timeout)
  });
  signal.addEventListener('abort', () => {
    controller = new AbortController();
  });

  return await Promise.race([
    nativeFetch(url, {...options, signal}).then(resp => {
      clearTimeout(timeoutId);
      
      resp.abort = controller.abort.bind(controller)
      return resp
    }).catch(error => {
      controller.abort();
      clearTimeout(timeoutId);
      throw error;
    }),
    timeoutProm()
  ])
}