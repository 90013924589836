import DataAccess from "../DataAccessStrategy";

export default class Notifications  extends DataAccess {
  constructor() {
    super("Notifications", {
      DATABASE_ID: Number, 
      id: Number, 
      company: String, 
      tite: String,
      body: String,
      question: String,
      url: String, 
      time: String,
      img: String,
      read: Boolean,
      active: Boolean,
    });
  }
}
