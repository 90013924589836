import React from "react";
import ErrosNotificationCenter from "../service/notifications/errosNotificationCenter";
import {
  createClient,
  getClientDataFromDB,
  getClientNextAppointment,
  saveClientEditData,
  updateModelToken,
  getClientEditions
} from '../service/client'
import {
  createSession,
  getClientSession,
  setClientSession,
  setClientValidators,
  isAuthenticated,
  logout,
  getCompanyData,
  setClientCompanyData,
} from "../service/authentication";
import { getSchedules } from "../service/schedules";
import { getStatements } from '../service/bankStatement';
import { getCompanyDataFromDB } from "../service/company";
import { MessageDialogContext } from "../contexts/MessageDialogContext";
import LocalStorage from "../utils/localStorage";
import * as moment from "moment";
import { WatchApp } from "../service/WatchApp";
import history from "../router/History";
import models from "../repository/models";
import { not } from "ramda";
import { Typography, Grid, Divider } from "@material-ui/core";
import ApiErrorResponse from "../errorDefinition/ApiErrorResponse";

const localStorage = LocalStorage.instance;

const UserContext = React.createContext();

class UserContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.unsubscribe = ErrosNotificationCenter.instance.subscribe(
      this.observable
    );
    this.state = {
      isAuthenticated: true,
      notificationsArray: []
    };
    isAuthenticated()
      .then((resp) => {
        this.setState({ isAuthenticated: resp });
      })
      .catch((err) => {
        this.setState({ isAuthenticated: false });
      });
  }

  componentDidMount() {
    if (this.state.isAuthenticated) {
      window.addEventListener("online", this.onOnline, false);
      if (navigator.onLine) {
        this.onOnline();
      }
    }

    setClientValidators()
      .then(r => console.log('SUCCESS', r))
      .catch(err => console.log("ERROR", err));

    this.setStoredValues();

    this.removeOldEventsFromBD();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.state.notificationsArray) != JSON.stringify(prevState.notificationsArray))
      await this.setStoredValues();
  }


  componentWillUnmount() {
    this.unsubscribe();
  }

  removeOldEventsFromBD() {
    setTimeout(() => {
      requestIdleCallback(() => {
        window.ERROR_LOG && window.ERROR_LOG.clearOldRecords();
      });
    }, 20 * 1000);
  }

  async verifyLastUpdate() {
    const twoMonthsLater = moment().subtract(2, "months");
    const lastUpdate = await localStorage.getItem('LAST_UPDATE_KEY');

    if (!lastUpdate) {
      this.logoutUser(true);
    }

    const shouldLogout = twoMonthsLater.isSameOrAfter(lastUpdate);
    if (shouldLogout) {
      this.logoutUser(true);
      await this.context.addAsyncDialog({
        title: "Encontramos um problema com seu login",
        type: "error",
        message:
          "Seu token de acesso expirou. Você será redirecionado à pagina de login.",
        hasCloseButton: false,
        handleConfirm: () => {
          this.setState({ isAuthenticated: false }, () => history.push("/"));
          this.context.clearAllDialogs();
        },
      });
    }
  }

  setStoredValues = async () => {
    this.setState({
      notificationsArray: await this.getClientNotifications(true),
      isLoading: false,
    });
  };

  observable = async (msg, helperData) => {
    if (this.state.isAuthenticated) {
      if (
        helperData.errorClassName == "InvalidAccessToken" ||
        helperData.errorClassName == "SessionDataLostError"
      ) {
        await logout("", true);
        await this.context.addAsyncDialog({
          title: "Encontramos um problema com seu login",
          type: msg,
          message: "Clique em Ok para ir para a página de login.",
          hasCloseButton: false,
          handleConfirm: () => {
            this.setState({ isAuthenticated: false }, () => history.push("/"));
            this.context.clearAllDialogs();
          },
        });
      }
    }
  };

  logoutUser = async (forceLogout = false) => {
    try {
      await logout(forceLogout);
      this.setState({ isAuthenticated: false });
    } catch (err) {
      console.log(err)
    }
  };

  createClientInfo = async (clientData) => {
    try {

      const companyData = await getCompanyData();
      let clientId = await createClient(clientData, companyData);
      await createSession(clientId);

      let sessao = await getClientSession();

      this.setState({ isAuthenticated: true });

    } catch (err) {
      this.setState({ isAuthenticated: false });
      throw err;
    }
  };

  createClientSchedule = async () => {
    try {

    } catch (err) {
      throw err
    }
  }

  getClientData = async () => {
    try {
      const ls_clientSession = await getClientSession();
      const ls_companyData = await getCompanyData();
      if (!ls_clientSession || !ls_companyData) {
        // this.logoutUser()
        return
      }
      let user = await models.Logins.filter(log => (log.empresa == ls_companyData.empresa && log.clienteInfoId == ls_clientSession.clientInfoId))

      if (!user.length) return
      else user = user[0];

      let clientData = await getClientDataFromDB(user.token, user.clienteInfoId, user.empresa);
      if (clientData)
        clientData.login = user;

      return clientData;

    } catch (err) {
      console.log(err)
    }
  }

  getAllLogins = async () => {
    let logins = await models.Logins.getAll();
    if (!logins.length) return []
    return logins

  }

  getAllNotifications = async () => {
    let notifications = await models.Notifications.getAll();
    if (!notifications.length) return []
    return notifications
  }

  getClientNotifications = async (applyFilter = false) => {
    let notifcations = await models.Notifications.getAll();
    let clientSession = localStorage.getItem('clientSession');
    notifcations = await notifcations.filter((ntf) => {
      if (ntf.empresa == clientSession.empresa) {
        if (applyFilter) {
          return ntf.active != false;
        } else {
          return true;
        }
      }
      return false;
    }).reverse();
    return notifcations;
  }

  setClientNotification = async (notificationsArray) => {
    if (!Array.isArray(notificationsArray) || !notificationsArray.map) {
      notificationsArray = [notificationsArray];
    }
    let notificationModelObj = {};
    let proms = notificationsArray.map(async ntf => {

      if (!ntf.data['firebase-messaging-msg-data']) return
      const ntfObj = JSON.parse(ntf.data['firebase-messaging-msg-data'].data.notification)
      const dadosObj = JSON.parse(ntf.data['firebase-messaging-msg-data'].data.dados)

      const { empresa, question } = dadosObj;
      const { body, click_action, icon, title } = ntfObj;

      notificationModelObj = { 
        company: empresa,
        title: title,
        body: body,
        question: question,
        url: ntf.origin,
        time: new Date(),
        img: icon,
        read: false,
      }
      return await models.Notifications.create(notificationModelObj);
    })

    if (proms.length)
      this.context.addAsyncDialog({
        type: "info",
        message: <>
          <Grid item>
            <Typography><span>Título:</span> {notificationModelObj.title}</Typography>
          </Grid>
          <Divider style={{ width: '100%', margin: '15px 0' }} />
          <Grid item>
            <Typography><span>Mensagem:</span> {notificationModelObj.body}</Typography>
          </Grid>
          <Divider style={{ width: '100%', margin: '15px 0' }} />
          <Grid item>
            <Typography><span>Horário:</span> {moment().format('DD/MM/YYYY [às] HH:mm')}</Typography>
          </Grid>
        </>,
        title: "Você recebeu uma notificação",
        hasCloseButton: false,
      });

    return await Promise.all(proms);
  }

  deleteNotification = async (notification) => {
    try {
      if (!notification || !notification.DATABASE_ID) return
      notification.active = false;
      await models.Notifications.put(notification);
      await this.setStoredValues();
      return true;

    } catch (e) {
      await this.context.addAsyncDialog({
        title: "Oops, houve um problema",
        message: "Tivemos um problema ao deletar esse item, caso ocorra com frequencia, procure o suporte",
        type: "error",
        hasCloseButton: false,
      });
      return false;

    } finally {
      this.setStoredValues()

    }
  }

  toggleNotificationRead = async (notification, value) => {
    try {
      if (!notification || !notification.DATABASE_ID) return
      if (notification.read != 'undefined') notification.read = value;
      await models.Notifications.put(notification);

      await this.setStoredValues()
    } catch (error) {
      console.log("Falha ao mudar o estado de uma notificacao em UserContext");
    }
  }

  getClientSchedules = async (qnt = 10, page = 0, filter) => {

    const ls_companyData = await localStorage.getItem("companyData");
    const ls_clientSession = await localStorage.getItem("clientSession");
    // return dadosSessao;

    let user = await models.Logins.filter(log => (log.empresa == ls_companyData.empresa && log.clienteInfoId == ls_clientSession.clientInfoId))

    if (!user.length) return
    else user = user[0];
    let r = await getSchedules(user.token, user.clienteInfoId, user.empresa, qnt, page, filter);
    return r && r.agendamentos
  }

  getClientStatements = async (qnt = 10, page = 0, filter) => {
    const ls_companyData = await localStorage.getItem("companyData");
    const ls_clientSession = await localStorage.getItem("clientSession");
    // return dadosSessao;

    let user = await models.Logins.filter(log => (log.empresa == ls_companyData.empresa && log.clienteInfoId == ls_clientSession.clientInfoId))

    if (!user.length) return
    else user = user[0];
    let r = await getStatements(user.token, user.clienteInfoId, user.empresa, qnt, page, filter);
    return r && r.sortedRecebimentosVenda || [];

  }

  getClienteEditions = async (filter) => {
    const ls_companyData = await localStorage.getItem("companyData");
    const ls_clientSession = await localStorage.getItem("clientSession");
    // return dadosSessao;

    let user = await models.Logins.filter(log => (log.empresa == ls_companyData.empresa && log.clienteInfoId == ls_clientSession.clientInfoId))

    if (!user.length) return
    else user = user[0];
    let r = await getClientEditions(user.token, user.clienteInfoId, user.empresa, filter);
    return r
  }
  
  setClientSession = async (sessionToken) => {
    let clienteBanco = await models.Logins.filter(log => log.token == sessionToken);
    if (!clienteBanco.length) return false
    let sessionObj = {
      token: clienteBanco[0].token,
      clientInfoId: clienteBanco[0].clienteInfoId,
      empresa: clienteBanco[0].empresa
    }
    let companyObj = {
      link: clienteBanco[0].link,
      empresa: clienteBanco[0].empresa,
      clienteId: clienteBanco[0].clienteId
    }

    await setClientCompanyData(companyObj)
    await setClientSession(sessionObj)
    return true
  }


  saveClientEdit = async (clientData) => {
    const ls_clientSession = await getClientSession();
    const ls_companyData = await getCompanyData();
    if (!ls_clientSession || !ls_companyData) {
      // this.logoutUser()
      return
    }
    let user = await models.Logins.filter(log => (log.empresa == ls_companyData.empresa && log.clienteInfoId == ls_clientSession.clientInfoId))

    if (!user.length) return
    else user = user[0];
    let resp = await saveClientEditData(clientData, user.token, user.clienteInfoId)

    return !resp.error;
  }

  getHomeScreenClientData = async () => {
    try {

      let clientDataFromDB = await this.getClientData();
      if (!clientDataFromDB) return null;
      let clientNextAppointment = await getClientNextAppointment(clientDataFromDB.login.token, clientDataFromDB.login.clienteInfoId, clientDataFromDB.login.empresa);
      let companyDataFromDB = await getCompanyDataFromDB();

      if (!clientDataFromDB || !clientNextAppointment || !companyDataFromDB) {
        throw new ApiErrorResponse({
          message: 'Ocorreu um erro ao buscar os dados do cliente, caso o problema persista entre em contato com o suporte.',
          title: 'Oops, houve um problema',
          type: 'error',
          hint: "Algum erro ao buscar os dados do cliente e empresa.",
          method: 'contexts.usercontext.getHomeScreenClientData',
          hasCloseButton: false,
        })
      }

      return { clientDataFromDB, clientNextAppointment, companyDataFromDB };
    } catch (error) {
      console.log(error)
    }

  }

  updateModelToken = async () => {
    let newToken = await localStorage.getItem('fcm-token')
    let clientSession = await localStorage.getItem('clientSession');
    if (clientSession && newToken)
      await updateModelToken(newToken)
    return
  }


  handleSyncDialogClose = () => {
    this.setState({ initiateSync: false });
  };

  onOnline = () => {
    const checkServer = true;
    isAuthenticated(checkServer)
      .then((authenticated) => {
        if (!authenticated) {
          this.setState({ isAuthenticated: false }, () =>
            history.push("/scan")
          );
        }
        //  else {
        //   this.subscribeToRemoteAccess();
        // }
        window.removeEventListener("online", this.onOnline);
      })
      .catch((err) => {
        console.log(err)
        this.context.addAsyncDialog({
          type: "warning",
          message: "Houve um problema ao verificarmos o seu token",
          title: "Problema com a conexão",
        });
      });
  };

  // subscribeToRemoteAccess() {
  //   const additionalData = async () => {
  //     let empresa = await getCompanyData();
  //     // let rota = await LocalStorage.instance.getItem(MAIN_ROUTE);
  //     return {
  //       nome: "APP CLIENTE",
  //       identificador: ((empresa && empresa.empresa) || "SEM EMPRESA"),
  //       empresa: empresa,
  //       onPage: window.location.hash,
  //     };
  //   };
  //   WatchApp.init({ additionalData });
  // }

  render() {
    let { isAuthenticated, notificationsArray } = this.state;
    let { createClientInfo, logoutUser, getClientData, getHomeScreenClientData, saveClientEdit, getClientSchedules, getClientStatements, getAllLogins, getAllNotifications, setClientSession, getClientNotifications, setClientNotification, deleteNotification, toggleNotificationRead, updateModelToken, getClienteEditions } = this;
    return (
      <UserContext.Provider
        value={{
          isAuthenticated,
          notificationsArray,
          createClientInfo,
          getHomeScreenClientData,
          getClientData,
          saveClientEdit,
          logoutUser,
          getClientSchedules,
          getClientStatements,
          getAllLogins,
          setClientSession,
          getClientNotifications,
          getAllNotifications,
          setClientNotification,
          deleteNotification,
          toggleNotificationRead,
          updateModelToken,
          getClienteEditions,
        }}
      >
        {typeof this.props.children === "function"
          ? this.props.children()
          : this.props.children}

      </UserContext.Provider>
    );
  }
}

UserContextProvider.contextType = MessageDialogContext;

export { UserContextProvider, UserContext };
